window.logOut = logOut;
window.onload = trimLinks;

function trimLinks() {

	var user = window.getUserInfo();
	if (user !== null) {
		document.getElementById("user-name").innerHTML = user.name;
		document.getElementById("user-dropdown").style.display = "block";
		document.getElementById("About-navLink").style.display = "block";
		document.getElementById("Contact-navLink").style.display = "block";
		document.getElementById("Dashboard-navLink").style.display = "block";
		document.getElementById("FAQ-navLink").style.display = "block";
		document.getElementById("Register-navLink").style.display = "none";
		document.getElementById("Login-navLink").style.display = "none";

        if (window.location.pathname.toLowerCase().replace(/\//g, '') === 'faq') {
            document.getElementById("authenticated").style.display = "block";
        }

		// Role specific paths
		if (user.roles.find(function (element) {

			return element.name === "Dealer";
		})) {

			var supplyNavItem = document.getElementById("Supply-navLink");
			supplyNavItem.style.display = "block";

			var supplyNavLink = supplyNavItem.getElementsByClassName("nav-link")[0];
			supplyNavLink.href = "/supply/listings";
			supplyNavLink.innerHTML = "Supply";
		}
		else if (user.roles.find(function (element) {

			return element.name === "Insurer";
		})) {
			var supplyNavItem = document.getElementById("Supply-navLink");
			supplyNavItem.style.display = "block";

			var supplyNavLink = supplyNavItem.getElementsByClassName("nav-link")[0];
			supplyNavLink.href = "/supply/request";
			supplyNavLink.innerHTML = "Source";
		}

	} else {
		document.getElementById("About-navLink").style.display = "block";
		document.getElementById("Contact-navLink").style.display = "block";
		document.getElementById("Register-navLink").style.display = "block";
		document.getElementById("Login-navLink").style.display = "block";

		if (window.location.pathname === '/' || window.location.pathname.toLowerCase().replace(/\//g, '') === 'faq') {
            document.getElementById("annonymous").style.display = "block";
		}
        
	}
}

function logOut() {
	window.logUserOut();
	window.location.href = "/";
}
